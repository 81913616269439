<template>
    <base-slider
        v-if="items.length"
        class-slide="col-8 col-sm-6 col-lg-4"
    >
        <template
            v-for="(collection, index) in items"
            #[index]
        >
            <card-collection
                :key="`collection-${collection.id}`"
                :data="collection"
                :image-loading="firstImagesEager && index < 3 && 'eager' || 'lazy'"
            />
        </template>
    </base-slider>
</template>

<script>
import BaseSlider from './BaseSlider.vue';
import CardCollection from './CardCollection.vue';

export default {
    components: {
        BaseSlider,
        CardCollection,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },
        firstImagesEager: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

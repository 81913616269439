var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length
    ? _c("base-slider", {
        attrs: { "class-slide": "col-8 col-sm-6 col-lg-4" },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.items, function (collection, index) {
              return {
                key: index,
                fn: function () {
                  return [
                    _c("card-collection", {
                      key: `collection-${collection.id}`,
                      attrs: {
                        data: collection,
                        "image-loading":
                          (_vm.firstImagesEager && index < 3 && "eager") ||
                          "lazy",
                      },
                    }),
                  ]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }